import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { Layout, Text } from '../../components'
import { TEXTS } from '../../constants'
import { Button } from '../../components/button'

import styles from './order.module.css'

export default () => {
    const { text, heroes, pioneers } = useStaticQuery(graphql`
      {
        text: contentfulText(tid: {eq: "order-important"}) {
          title
          content {
            json
          }
        },
        heroes: contentfulAsset(title: {eq: "Герои победы"}) {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
        pioneers: contentfulAsset(title: {eq: "Пионеры герои"}) {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
      }`)
    return (
        <Layout header={text.title} pageTitle={text.title}>
            <Img fluid={heroes.localFile.childImageSharp.fluid} alt={TEXTS.orderImportantHeroes}
                 className={styles.image}/>
            <Text content={text.content.json}/>
            <Img fluid={pioneers.localFile.childImageSharp.fluid} alt={TEXTS.orderImportantPioneers}
                 className={styles.image}/>
            <Button text={'ТЕСТ «ГЕРОИ ПОБЕДЫ»'} to="https://diletant.media/state_exam/29231104/" mode="single"
                    external/>
        </Layout>
    )
}
